import "styles/pages/page-money.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import TextWithSymbol from "components/TextWithSymbol";
import CurrencyExchange from "components/CurrencyExchange";
import Breadcrumbs from "../components/Breadcrumbs";

const Currency = ({ pageContext }) => {
	const pageCurrency = pageContext.pageContent;
	return (
		<Layout headerColor="#F36900">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/waluta-bg-main.png").default}
			/>
			<Breadcrumbs currentLocationLabel="Pieniądze i waluta" />
			<section className="thailand-currency">
				<div className="container">
					<h2 className="section-title">
						{pageCurrency.currencyTitle}
					</h2>
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<div
								className="thailand-currency__text"
								dangerouslySetInnerHTML={{
									__html: pageCurrency.currencyDesc,
								}}
							></div>
							{pageCurrency.currencyLimits.map((node, index) => {
								return (
									<TextWithSymbol
										desc={node.desc}
										key={index}
									/>
								);
							})}
							<p>
								Aktualne informacje:{" "}
								<a
									href="https://www.bot.or.th"
									target="__blank"
								>
									https://www.bot.or.th
								</a>
							</p>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageCurrency.currencyImg?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="currency-exchange">
				<div className="container">
					<h2 className="section-title">
						{pageCurrency.actuallCurrency}
					</h2>
					<div className="row">
						<CurrencyExchange />
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default Currency;
