import React from "react";
import "./currency-view.scss";

const CurrencyView = ({ image, value, title }) => {
	return (
		<div className="currency-container">
			<img
				src={image}
				className="img-fluid currency-container__img"
				alt=""
			/>
			<div className="currency-container__title">{title}</div>
			<div className="currency-container__value">{value} BAT</div>
		</div>
	);
};

export default CurrencyView;
