import React, { useEffect, useState } from "react";
import axios from "axios";
import CurrencyView from "../CurrencyView/index";

const CurrencyExchange = () => {
  const [currencyPLN, setCurrencyPLN] = useState(null);
  const [currencyUSD, setCurrencyUSD] = useState(null);
  const [currencyEUR, setCurrencyEUR] = useState(null);
  const fetchCurrency = async () => {
    try {
      const {
        data: { data: responsePLN },
      } = await axios.get(
        "https://api.currencyapi.com/v3/latest?apikey=c2e9a020-2ce6-11ec-aa96-43743db5832a&base_currency=PLN"
      );
      const {
        data: { data: responseUSD },
      } = await axios.get(
        "https://api.currencyapi.com/v3/latest?apikey=c2e9a020-2ce6-11ec-aa96-43743db5832a&base_currency=USD"
      );
      const {
        data: { data: responseEUR },
      } = await axios.get(
        "https://api.currencyapi.com/v3/latest?apikey=c2e9a020-2ce6-11ec-aa96-43743db5832a&base_currency=EUR"
      );

      setCurrencyPLN(responsePLN?.THB?.value.toFixed(2));
      setCurrencyUSD(responseUSD?.THB?.value.toFixed(2));
      setCurrencyEUR(responseEUR?.THB?.value.toFixed(2));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchCurrency();
  }, []);

  return (
    <>
      <div className="col-xl-2 offset-xl-2">
        <CurrencyView
          title="1 PLN to"
          image={require("../../assets/images/money-flag-poland.png").default}
          value={currencyPLN}
        />
      </div>
      <div className="col-xl-2 offset-xl-1">
        <CurrencyView
          title="1 EURO to"
          image={require("../../assets/images/money-flag-euro.png").default}
          value={currencyEUR}
        />
      </div>
      <div className="col-xl-2 offset-xl-1">
        <CurrencyView
          title="1 USD to"
          image={require("../../assets/images/money-flag-usa.png").default}
          value={currencyUSD}
        />
      </div>
    </>
  );
};

export default CurrencyExchange;
